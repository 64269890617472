import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  VisuallyHidden,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, Dispatch } from "react";
import { useForm } from "react-hook-form";
import { useVerifyRecaptcha } from "../../utils/hooks";
import AlertModal from "../misc/AlertModal";

interface JournalCommentModalProps {
  onClose: () => void;
  isOpen: boolean;
  tripId: string;
  globalState: GlobalState;
  setGlobalState: Dispatch<React.SetStateAction<GlobalState>>;
}


const JournalCommentModal = (props: JournalCommentModalProps):JSX.Element => {
  
  const { register, handleSubmit, formState, reset } = useForm<{ name: string, comment: string }>({
    defaultValues: {
      name: '',
      comment: ''
    }
  });

  const [alertModalData, setAlertModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: `Your comment has been added. Thanks!`
  });
  
  const alertModal = useDisclosure();

  const verifyRecaptcha = useVerifyRecaptcha();
  
  const submitCommentForm = async (values) => {
    const submitData = { name: values.name, comment: values.comment };

    try {
      const verifyToken = await verifyRecaptcha('journal_comment_submit');

      if (verifyToken.ok) {
        const sendForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/${props.tripId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submitData)
        })
    
        if (sendForm.ok) {
          const updatedComments = await sendForm.json();
  
          props.setGlobalState({
            ...props.globalState,
            journalData: {
              ...props.globalState.journalData,
              COMMENTLIST: updatedComments
            }
          });
  
          setAlertModalData({
            icon: ['far', 'circle-check'] as IconProp,
            iconColor: 'brand.green.600',
            title: 'Got It!',
            content: `Your comment has been added. Thanks!`
          });
          
          props.onClose();
          alertModal.onOpen();
          reset();
    
        } else {
          console.error(sendForm);
          throw new Error('Failed to send comment form')
        }
    
        alertModal.onOpen();
      }
  
    } catch (error) {
      console.error(error);
      setAlertModalData({
        icon: ['fas', 'xmark'] as IconProp,
        iconColor: 'red.400',
        title: 'Oops!',
        content: 'Sorry, but there was an error submitting your comment. Please try again later.'
      });
  
      alertModal.onOpen();
    }
  }

  return (
    <>
      <AlertModal
        onClose={alertModal.onClose}
        isOpen={alertModal.isOpen}
        iconColor={alertModalData.iconColor}
        icon={alertModalData.icon}
        title={alertModalData.title}
        content={alertModalData.content}
      />

      <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center w="100%" flexDirection="column">
              <Box
                color='brand.green.600'
                my="4"
              >
                <FontAwesomeIcon
                  icon={'message'}
                  size={'3x' as SizeProp}
                />
              </Box>
              <Text as="h2">
                Leave a Comment
              </Text>
            </Center>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(submitCommentForm)}>
              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['name']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <VisuallyHidden>
                  <FormLabel htmlFor={'name'}>
                    Your Name
                  </FormLabel>
                </VisuallyHidden>

                <Input
                  name={'name'}
                  type={'text'}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Your Name'}
                  {...register('name', { required: 'Name is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['name']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>
              
              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['name']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <VisuallyHidden>
                  <FormLabel htmlFor={'name'}>
                    Comment
                  </FormLabel>
                </VisuallyHidden>

                <Textarea
                  name={'comment'}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Your Comments'}
                  {...register('comment', { required: 'Comment is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['comment']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                width="full"
                colorScheme={'brand.orange'}
                variant={'solid'}
                leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                disabled={formState.isSubmitting}
                mb={5}
              >
                Send Comment
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default JournalCommentModal;