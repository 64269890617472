import { Box, Button, Flex, FormControl, FormLabel, Input, LinkBox, Link, LinkOverlay, Radio, RadioGroup, Stack, Text, Tooltip, useInterval, Tabs, Tab, TabList, TabPanels, TabPanel } from "@chakra-ui/react"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { storyblokEditable } from "@storyblok/react"
import { useStripe, useElements, Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useContext, useEffect, useMemo, useState } from "react"
import { createSubscription } from "../../../utils/ppm365Api"
import { PartnerProfileContext, PartnerSupportContext, PartnerSupportStatusContext } from "../../contexts/ppm365"
import { DynamicComponentProps } from "../../DynamicComponent"
import SbSignInButton from "../../misc/sbSignInButton"
import StripeSubscriptionForm from "../../stripe/StripeSubscriptionForm"
import { LocationLink, LocationLinkType } from "../../locations/LocationLink"
import SignUpForm from "../../layout/navigation/sign-in/SignUpForm"
import SignInForm from "../../layout/navigation/sign-in/SignInForm"
import ButtonModal from "../../buttons/ButtonModal"

export interface PartnerSupportFormProps extends DynamicComponentProps {

}

const PartnerSupportForm = ({ blok, globalState, setGlobalState, locale, locationState, ppm365 }: PartnerSupportFormProps): JSX.Element => {

    const profile = useContext(PartnerProfileContext)
    const support = useContext(PartnerSupportContext)
    const currentSupportStatus = useContext(PartnerSupportStatusContext)

    const [churchAdvocateCode, setChurchAdvocateCode] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [supportOption, setSupportOption] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [subscriptionError, setSubscriptionError] = useState<CreateSubscriptionError>("none")
    const [subscriptionComplete, setSubscriptionComplete] = useState(false)
    const [selectedSupportOption, setSelectedSupportOption] = useState(null)
    const [supportStatusReloadDelay, setSupportStatusReloadDelay] = useState(null)



    useEffect(() => {
        setSelectedSupportOption(support.supportOptions.find(o => o.stripePriceId === supportOption))
    },
        [supportOption, support.supportOptions])

    useInterval(() => {
        currentSupportStatus.reloadStatus()
    }, supportStatusReloadDelay)

    useEffect(() => {
        if (currentSupportStatus?.isSupporter) {
            setSupportStatusReloadDelay(null)
        }
    }, [currentSupportStatus])

    const ppm365Location = useMemo(() => {
        return globalState.ppm365Locations.find(l => l.rootsId === profile?.locationId)
    }, [globalState.ppm365Locations, profile?.locationId])

    const subscribe = async () => {
        setSubmitting(true)
        setSubscriptionError("none")
        const [subscription, error] = await createSubscription({ partnerNumericId: profile.id, priceId: supportOption, churchAdvocateCode: churchAdvocateCode })

        if (error === "none") {
            setClientSecret(subscription.clientSecret)
            setSubmitting(false)
        }
        else {
            setSubscriptionError(error)
            setSubmitting(false)
        }

    }

    const subscriptionCompleteHandler = () => {
        setSubscriptionComplete(true)
        window.scrollTo(0, 0)
        setSupportStatusReloadDelay(3000)


    }

    const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

    return (
        <Flex direction="inherit" alignItems={"inherit"} justifyItems="inherit" {...storyblokEditable(blok)}>

            {
                subscriptionComplete && <Text><FontAwesomeIcon icon={["fas", "circle-notch"]} className="fa-spin"></FontAwesomeIcon> Thank you for supporting the local Church!  Please wait while we finalize some details.</Text>
            }

            {!subscriptionComplete && (!globalState.user?.email || (!currentSupportStatus || currentSupportStatus.isSupporter === false)) &&
                <>
                    <RadioGroup marginBottom={5} isDisabled={(clientSecret || submitting) ? true : false} onChange={setSupportOption} value={supportOption} >
                        <Stack direction="column">
                            {support.supportOptions.map(o => {
                                return (
                                    <Radio key={o.stripePriceId} size="sm" value={o.stripePriceId}>{o.name} {o.description && <Tooltip hidden={o.description ? false : true} label={o.description}><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon></Tooltip>}</Radio>
                                )
                            })}
                        </Stack>
                    </RadioGroup>
                    {ppm365Location.ppm365DonationFund &&
                        <ButtonModal
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                            locationState={locationState}
                            locale={locale}
                            ppm365={ppm365}
                            blok={{
                                _uid: "",
                                component: "buttonModal",
                                autoOpenParameter: "",
                                centerVertically: false,
                                showFooter: false,
                                modalSize: "xl",
                                closeOnOverlayClick: true,
                                modalTitle: "Give to PPM365",

                                button: {
                                    buttonText: <>OR<br />Make a one time donation towards the PPM365 program</>,
                                    colorScheme: "brand.green",
                                    size: "sm",
                                    height: 14,
                                    variant: "outline",
                                    fontWeight: "normal",
                                    plugin: "chakra-button",
                                    _uid: "",
                                    width: "auto",
                                    whiteSpace: "initial",
                                    lineHeight: 1.2

                                },
                                modalContent: [
                                    {
                                        width: "full",
                                        alignment: "center",
                                        verticalAlignment: "top",
                                        component: "box",
                                        content: [
                                            {
                                                _uid: "",
                                                amounts: [
                                                    { _uid: "3", component: "donationAmount", itemKey: "", custom: true, value: "", description: "" }
                                                ] as AmountBlok[],
                                                component: "donationForm",
                                                customFields: [
                                                    { _uid: "4", component: "donationCustomField", name: "ppm365 referral", displayText: "How did you hear about PPM365?" },
                                                    { _uid: "5", component: "donationCustomField", name: "church advocate code", displayText: "Church Advocate Code (Optional)" },
                                                    { _uid: "6", component: "donationCustomField", name: "ppm365 shirt size", displayText: "T-Shirt Size (if you desire to receive a PPM365 T-Shirt)" },
                                                ],
                                                funds: [{ displayName: ppm365Location.name + " PPM365", fund: ppm365Location.ppm365DonationFund }],
                                                type: "ppm365"

                                            } as DonationBlok
                                        ] as Blok[]

                                    } as BoxBlok
                                ]
                            }}
                        ></ButtonModal>
                    }
                </>
            }

            {
                !globalState.user?.email &&
                <>
                    <Text marginTop={5} fontSize={"smaller"}>If you have been on a PPM trip, you will use your Pelican Account credentials to log in. If you have not been on a PPM trip, please create an account.</Text>
                    <Tabs colorScheme={"brand.green"} w={"full"}>
                        <TabList>
                            <Tab>Sign In</Tab>
                            <Tab>Create Account</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <SignInForm buttonText="Sign in to Donate" globalState={globalState} setGlobalState={setGlobalState} locale={locale} blok={blok}></SignInForm>
                            </TabPanel>
                            <TabPanel>
                                <SignUpForm buttonText="Create an Account to Donate" globalState={globalState} setGlobalState={setGlobalState} locale={locale} blok={blok}></SignUpForm>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>


                </>
            }



            {!subscriptionComplete && (!globalState.user?.email || (!currentSupportStatus || currentSupportStatus.isSupporter === false)) &&
                <>

                    <FormControl isDisabled={(clientSecret || submitting) ? true : false} marginBottom={5}>
                        <FormLabel fontSize={"smaller"}>Church Advocate Code (optional)</FormLabel>
                        <Input type="text" value={churchAdvocateCode} onChange={(e) => setChurchAdvocateCode(e.target.value)}></Input>
                    </FormControl>


                    {subscriptionError === "unauthorized" && <Text as="p" textColor="red.600" textAlign={'center'}>Please sign in to continue</Text>}
                    {subscriptionError === "unknown" && <Text as="p" textColor="red.600" textAlign={'center'}>There was a problem setting up your donation</Text>}




                    {
                        !clientSecret && globalState.user?.email &&
                        <Button
                            onClick={() => subscribe()}
                            disabled={submitting || !supportOption}
                            colorScheme="brand.green"
                            leftIcon={submitting ? <FontAwesomeIcon icon={["fas", "circle-notch"]} className="fa-spin"></FontAwesomeIcon> : undefined}
                        >
                            Select Level
                        </Button>
                    }



                    {clientSecret &&
                        <>

                            <Box marginTop={4} w={"full"}>
                                <Flex w={"full"} paddingX={4} justifyContent={"flex-start"}>
                                    <FormLabel>Name:</FormLabel>
                                    <Text fontWeight={"bold"}>{globalState.user.name}</Text>
                                </Flex>
                                <Flex paddingX={4}>
                                    <FormLabel>Email:</FormLabel>
                                    <Text fontWeight={"bold"}>{globalState.user.email}</Text>
                                </Flex>
                                <Text paddingX={4}>Not you?  Sign out and log in with your Pelican Account</Text>


                                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                                    <StripeSubscriptionForm
                                        clientSecret={clientSecret}
                                        globalState={globalState}
                                        supportOption={selectedSupportOption}
                                        subscriptionComplete={subscriptionCompleteHandler} ></StripeSubscriptionForm>
                                </Elements>

                            </Box>
                        </>
                    }
                </>
            }
        </Flex>
    )
}

export default PartnerSupportForm